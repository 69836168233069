import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { LogoPng, TravelingJpg } from "../../Assets";
import Card from "../../Components/Card";
import InputError from "../../Components/InputError";
import InputLabel from "../../Components/InputLabel";
import PrimaryButton from "../../Components/PrimaryButton";
import TextInput from "../../Components/TextInput";
import Head from "../../Components/Head";
import { useTranslation } from "react-i18next";
import { Toaster } from "react-hot-toast";
import Loader from "../../Components/Loader";
import { Carousel } from "flowbite-react";
import { useClient } from "../../Utils/Context/ClientContext";
import StatusCode from "../../Utils/Enums/StatusCode";
import DefaultFooter from "../../Sections/DefaultFooter";

export default function Check() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { fetchByEmail } = useClient();

  const attemptCheckEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let client = await fetchByEmail({ email: email });
    client.data.length > 0 &&
      navigate(
        `/our-services?status=${StatusCode.ACCOUNT_ALREADY_REGISTERED}&email=${email}`
      );
    client.data.length === 0 && navigate(`/client/register?email=${email}`);
  };

  return (
    <>
      <div className="h-fit dark:bg-gray-700 bg-slate-100 bg-[url('/src/Assets/bg-landmark-1.svg')] bg-no-repeat bg-contain">
        <Head title={t("Register new client")} />
        <Toaster />
        <div className="px-10 py-5">
          <div className="bg-slate-200 dark:bg-slate-500 md:bg-opacity-0 rounded-3xl p-3">
            <h2 className="text-2xl md:text-3xl text-center dark:text-white font-bold w-full">
              {t("Welcome to Konsultan Visa")}
            </h2>
            <p className="text-center dark:text-white">
              {t("Let's start your journey with us")}
            </p>
            <p className="text-center dark:text-white">
              {t("We believe that Everyone Can Overseas")}
            </p>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-5 px-10 md:px-28 md:grid-cols-2">
          <div className="aspect-video">
            <Carousel className="rounded-3xl">
              <img src={TravelingJpg} alt="..." className="rounded-3xl" />
            </Carousel>
          </div>
          <form onSubmit={attemptCheckEmail}>
            <Card className={`m-0`}>
              <div className="flex justify-center items-center w-full">
                <div className="w-full">
                  <InputLabel
                    htmlFor="email"
                    className="text-center"
                    value={t("Email") + "*"}
                  />

                  <TextInput
                    id="email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    className="mt-1 block w-full text-center"
                    autoComplete="email"
                    required
                    // isError={errorMessage?.["user.email"]}
                  />

                  <InputError
                    // message={errorMessage?.["user.email"]?.[0] ?? ""}
                    className="mt-2"
                  />
                </div>
              </div>
            </Card>
            <p className="text-md my-3 italic text-red-400 px-5">
              {"*" + t("Required.")}
            </p>

            <div className="flex justify-center m-4">
              <PrimaryButton disabled={isLoading} type="submit">
                {isLoading ? <Loader /> : "Submit"}
              </PrimaryButton>
            </div>
          </form>
        </div>

        <div className="flex justify-center m-10">
          <img alt="" src={LogoPng} className="w-1/2 md:w-1/6" />
        </div>
        <DefaultFooter />
      </div>
    </>
  );
}
