import { useState } from "react";
import { instanceRequest } from "../Network/Instance";

import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import moment from "moment";

export const useClient = () => {
  const { t } = useTranslation();
  const [status, setStatus] = useState({
    isLoading: true,
    isError: false,
    isSuccess: false,
    isSuccessUpdate: false,
    isSuccessGet: false,
  });
  const [clients, setClients] = useState(null);
  const [client, setClient] = useState(null);

  const handleApiError = (e) => {
    setStatus({
      isLoading: false,
      isError: true,
      isSuccess: false,
      isSuccessUpdate: false,
    });
    toast.error(e.response?.data?.message ?? t("default_error_message"));
  };
  const handleFinish = (successType) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      isLoading: false,
      isError: false,
      [successType]: true,
    }));
  };
  const handleInitial = () =>
    setStatus({ isLoading: true, isError: false, isSuccess: false });

  const fetchData = async (request, endpoint, config, data, successType) => {
    handleInitial();
    try {
      const response = await instanceRequest(request, endpoint, config, data);
      handleFinish(successType);
      return response.data;
    } catch (error) {
      handleApiError(error);
    }
  };

  const fetchAll = async ({ page, keyword, limit = 10, today }) => {
    const config = {
      params: {
        page: page,
        limit: limit,
        include: [
          "user.placementTest",
          "school",
          "user.visits",
          "handledBy.user",
        ],
        "filter[user.name]": keyword,
        sort: ["-last_visit", "-created_at"],
      },
    };
    if (today) {
      config.params["filter[last_visit]"] = moment().format("YYYY-MM-DD");
    }

    setClients(
      await fetchData("get", "client", config, undefined, "isSuccessGet")
    );
  };

  const fetchByEmail = async ({ email }) => {
    const config = {
      params: {
        include: [
          "user.placementTest",
          "school",
          "user.visits",
          "handledBy.user",
        ],
        "filter[user.email]": email,
      },
    };
    return await fetchData("get", "client", config, undefined, "isSuccessGet");
  };

  const fetchById = async (id) => {
    const config = {
      params: {
        include: [
          "user.placementTest",
          "school",
          "user.visits",
          "handledBy.user",
        ],
      },
    };
    setClient(
      await fetchData("get", `client/${id}`, config, undefined, "isSuccessGet")
    );
  };

  const fetchByUserId = async ({ user_id, page }) => {
    const config = {
      params: {
        "filter[user_id]": user_id,
        page: page,
      },
    };
    setClient(
      await fetchData("get", `client`, config, undefined, "isSuccessGet")
    );
  };

  const update = async (id, data) => {
    const result = await fetchData(
      "put",
      `client/${id}`,
      undefined,
      data,
      "isSuccessUpdate"
    );
    return result;
  };

  return {
    ...status,
    clients,
    client,
    update,
    fetchAll,
    fetchById,
    fetchByEmail,
    fetchByUserId,
  };
};
