import React, { useEffect, useState } from "react";
import { LogoPng, SuccessLottie } from "../Assets";
import { useLocation } from "react-router-dom";
import Head from "../Components/Head";
import { useTranslation } from "react-i18next";
import { Card } from "flowbite-react";
import { useProgram } from "../Utils/Context/ProgramContext";
import { getMessage } from "../Utils/Enums/StatusCode";
import { useClient } from "../Utils/Context/ClientContext";
import Loader from "../Components/Loader";
import { useVisit } from "../Utils/Context/VisitContext";
import Service, { getServiceLabel } from "../Utils/Enums/Service";
import Lottie from "lottie-react";
import Alert from "../Components/Alert";

export default function OurServices() {
  const { t } = useTranslation();
  const { fetchAll } = useProgram();
  const { fetchByEmail, isLoading } = useClient();
  const { store, isSuccess } = useVisit();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const status = parseInt(queryParams.get("status"), 10);
  const email = queryParams.get("email");
  const [client, setClient] = useState(null);
  const [selectedService, setSelectedService] = useState(null);

  useEffect(() => {
    fetchAll({});
    async function getData() {
      let dataClient = await fetchByEmail({ email: email });
      dataClient.data.length > 0 && setClient(dataClient.data[0]);
    }
    email !== null && getData();
  }, []);

  const attemptUpdate = async (service) => {
    setSelectedService(service);
    await store(client.user.id, service);
  };

  return (
    <div className="w-screen min-h-screen dark:bg-slate-800 flex flex-col justify-center">
      <Head title={t("Success")} />

      <div className="flex justify-center items-center flex-col m-5">
        {status === 200 && (
          <div className="w-1/2 md:w-1/12">
            <Lottie animationData={SuccessLottie} />
          </div>
        )}
        <Alert
          isSuccess
          title={t(`StatusCode_${getMessage(status)}`)}
          subTitle={t("subTitleSuccessRegisterClient")}
        />
      </div>

      {client !== null && (
        <>
          <div className="w-full dark:text-white text-center">
            <h2 className="italic text-xl">{t("Welcome back")}</h2>
            <h1 className="text-bold text-3xl">{client.user?.name}</h1>
            <div className="flex items-center justify-center">
              <Card className="m-5 rounded-3xl w-full md:w-1/2">
                {isSuccess && (
                  <div className="flex justify-center items-center">
                    <Lottie className="w-7 h-7" animationData={SuccessLottie} />
                  </div>
                )}
                <h2>
                  {isSuccess
                    ? t("You choose:")
                    : t("What do you want a consultation for?")}
                </h2>
                <div className="flex flex-col gap-3">
                  {!isSuccess &&
                    Object.entries(Service).map((item, index) => {
                      return (
                        <Card
                          className="cursor-pointer"
                          key={index}
                          onClick={() => attemptUpdate(item[1])}
                        >
                          {t(`Service_${item[0]}`)}
                        </Card>
                      );
                    })}
                  {isSuccess && (
                    <>
                      <Card>
                        {t(`Service_${getServiceLabel(selectedService)}`)}
                      </Card>
                    </>
                  )}
                </div>
                {isLoading && (
                  <div className="w-full text-centerr">
                    <Loader />
                  </div>
                )}
                {isSuccess &&
                  t("Please show this page to our staff for further service")}
              </Card>
            </div>
          </div>
        </>
      )}
      <div className="flex flex-col w-full h-auto p-5 md:p-28">
        <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
          <div class="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
            <h2 class="text-2xl font-bold md:text-4xl md:leading-tight dark:text-white">
              Check our services
            </h2>
          </div>

          <div class="mt-12 grid sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:items-center">
            <div class="flex flex-col border border-gray-200 text-center rounded-xl p-8 dark:border-white">
              <h4 class="font-medium text-lg text-gray-800 dark:text-neutral-200">
                Study Abroad
              </h4>
              <span class="mt-7 font-bold text-5xl text-gray-800 dark:text-neutral-200">
                Study Abroad
              </span>
              <p class="mt-2 text-sm text-gray-500 dark:text-neutral-500">
                Study and Internship in Australia, Singapore, Switzerland, etc.
              </p>

              <ul class="mt-7 space-y-2.5 text-sm">
                <li class="flex space-x-2">
                  <svg
                    class="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span class="text-gray-800 dark:text-neutral-400">
                    Flexible
                  </span>
                </li>

                <li class="flex space-x-2">
                  <svg
                    class="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span class="text-gray-800 dark:text-neutral-400">
                    Partime<span className="text-red-300 text-sm">*</span>
                  </span>
                </li>

                <li class="flex space-x-2">
                  <svg
                    class="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span class="text-gray-800 dark:text-neutral-400">
                    Paid Internship
                    <span className="text-red-300 text-sm">*</span>
                  </span>
                </li>
              </ul>

              <a
                class="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-gray-500 hover:border-blue-600 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-blue-500 dark:hover:border-blue-600"
                href="https://service.konsultanvisa.com/study"
              >
                Learn more!
              </a>
            </div>

            <div class="flex flex-col border-2 border-blue-600 text-center shadow-xl rounded-xl p-8 dark:border-blue-700">
              <p class="mb-3">
                <span class="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-lg text-xs uppercase font-semibold bg-blue-100 text-blue-800 dark:bg-blue-600 dark:text-white">
                  Most popular
                </span>
              </p>
              <h4 class="font-medium text-lg text-gray-800 dark:text-neutral-200">
                Internship Program
              </h4>
              <span class="mt-5 font-bold text-5xl text-gray-800 dark:text-neutral-200">
                Internship Program
              </span>
              <p class="mt-2 text-sm text-gray-500 dark:text-neutral-500">
                Embark on your new career. Internship Program for up to 12
                months.
              </p>

              <ul class="mt-7 space-y-2.5 text-sm">
                <li class="flex space-x-2">
                  <svg
                    class="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span class="text-gray-800 dark:text-neutral-400">
                    Paid Internship
                  </span>
                </li>

                <li class="flex space-x-2">
                  <svg
                    class="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span class="text-gray-800 dark:text-neutral-400">
                    Living Cost Support
                    <span className="text-red-300 text-sm">*</span>
                  </span>
                </li>

                <li class="flex space-x-2">
                  <svg
                    class="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span class="text-gray-800 dark:text-neutral-400">
                    Accomodation Support
                    <span className="text-red-300 text-sm">*</span>
                  </span>
                </li>
              </ul>

              <a
                class="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
                href="https://service.konsultanvisa.com/internship"
              >
                Learn more!
              </a>
            </div>

            <div class="flex flex-col border border-gray-200 text-center rounded-xl p-8 dark:border-white">
              <h4 class="font-medium text-lg text-gray-800 dark:text-neutral-200">
                Work & Holiday Visa
              </h4>
              <span class="mt-5 font-bold text-5xl text-gray-800 dark:text-neutral-200">
                WHV
              </span>
              <p class="mt-2 text-sm text-gray-500 dark:text-neutral-500">
                Work & Holiday Visa Australia
              </p>

              <ul class="mt-7 space-y-2.5 text-sm">
                <li class="flex space-x-2">
                  <svg
                    class="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span class="text-gray-800 dark:text-neutral-400">
                    Fulltime Work
                  </span>
                </li>

                <li class="flex space-x-2">
                  <svg
                    class="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span class="text-gray-800 dark:text-neutral-400">
                    Affordable Price
                  </span>
                </li>

                <li class="flex space-x-2">
                  <svg
                    class="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span class="text-gray-800 dark:text-neutral-400">
                    Up To 3 Years<span className="text-red-300 text-sm">*</span>
                  </span>
                </li>
              </ul>

              <a
                class="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-gray-500 hover:border-blue-600 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-blue-500 dark:hover:border-blue-600"
                href="https://service.konsultanvisa.com/whv/"
              >
                Learn more!
              </a>
            </div>

            <div class="flex flex-col border border-gray-200 text-center rounded-xl p-8 dark:border-white">
              <h4 class="font-medium text-lg text-gray-800 dark:text-neutral-200">
                Visitor/Tourist Visa
              </h4>
              <span class="mt-5 font-bold text-5xl text-gray-800 dark:text-neutral-200">
                Holiday Visa
              </span>
              <p class="mt-2 text-sm text-gray-500 dark:text-neutral-500">
                Visitor/Tourist Visa all over world
              </p>

              <ul class="mt-7 space-y-2.5 text-sm">
                <li class="flex space-x-2">
                  <svg
                    class="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span class="text-gray-800 dark:text-neutral-400">
                    Afferdable Price
                  </span>
                </li>

                <li class="flex space-x-2">
                  <svg
                    class="flex-shrink-0 mt-0.5 size-4 text-blue-600 dark:text-blue-500"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <polyline points="20 6 9 17 4 12" />
                  </svg>
                  <span class="text-gray-800 dark:text-neutral-400">
                    Multiple Entry up to 3 years
                    <span className="text-red-300 text-sm">*</span>
                  </span>
                </li>
              </ul>

              <a
                class="mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-gray-500 hover:border-blue-600 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-blue-500 dark:hover:border-blue-600"
                href="https://service.konsultanvisa.com/visitor"
              >
                Learn more!
              </a>
            </div>
          </div>
          <div className="w-full flex justify-center items-center">
            <a
              class="my-5 md:w-fit mx-10 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-gray-200 text-gray-500 hover:border-blue-600 hover:text-blue-600 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-neutral-400 dark:hover:text-blue-500 dark:hover:border-blue-600"
              href="https://service.konsultanvisa.com"
            >
              Another service!
            </a>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <img alt="logo" src={LogoPng} className="w-1/4" />
      </div>
    </div>
  );
}
